<template>
  <Actions :actions="data.actions" />
</template>

<script>
/* eslint-disable no-debugger */
import Actions from "@/components/Actions";
import { onMounted } from "vue";
import { useAction } from "./useAction";
import { useStore } from "vuex";
export default {
  components: {
    Actions,
  },
  setup() {
    const store = useStore();
    onMounted(async () => {
      await store.dispatch("action/getUpdates", 0);
    });
    const { data } = useAction();

    return {
      data,
    };
  },
};
</script>
