import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export function useAction() {
  const store = useStore();

  const data = reactive({
    actions: computed(() => store.getters['action/activeActions']),
  });

  return {
    data,
  };
}
